import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import {
  baseUploadsUrl,
  baseLibraryUrl,
  defaultProfileImage,
  logo,
  baseStage,
  baseClient,
  contentSource,
  contentSourceSite,
  CONST_STRINGS,
  DEFAULT_CURRENCY,
} from '../config';
import { extensionFeatureInfo, extensionAliases, PlussCore } from '../config/features';
import EventsContent from '../screens/DangerZone/FeaturePicker/Preview/WidgetContent/EventsContent';
import GalleryContent from '../screens/DangerZone/FeaturePicker/Preview/WidgetContent/GalleryContent';
import PeopleContent from '../screens/DangerZone/FeaturePicker/Preview/WidgetContent/PeopleContent';
import { isFeatureEnabled, isTheBest, validateAccess } from '../session';

const { Helper } = PlussCore;
export const getUrlParams = Helper.getUrlParams;
export const safeReadParams = Helper.safeReadParams;

export const get1400 = Helper.get1400;
export const getThumb300 = Helper.getThumb300;
export const generateImageName = Helper.generateImageName;
export const getExtension = Helper.getExtension;
export const getFileName = Helper.getFileName;
export const isVideo = Helper.isVideo;

export const getFirstName = Helper.getFirstName;
export const isEmail = Helper.isEmail;
export const isUrl = Helper.isUrl;
export const randomString = Helper.randomString;
export const toParagraphed = Helper.toParagraphed;
export const onlyAlphanumeric = Helper.onlyAlphanumeric;

export const getSiteName = Helper.getSiteName;
export const getSiteNameFromRoles = Helper.getSiteNameFromRoles;
export const getSiteSettingFromState = Helper.getSiteSettingFromState;
export const getSiteLevelFromState = Helper.getSiteLevelFromState;
export const getMerchantsFromState = Helper.getMerchantsFromState;

export const readJSONFromStorage = Helper.readJSONFromStorage;
export const setLocalStorage = Helper.setLocalStorage;

export const getTimepickerTime = Helper.getTimepickerTime;
export const timeToMinutes = Helper.timeToMinutes;
export const timepickerToMinutes = Helper.timepickerToMinutes;
export const minutesToString = Helper.minutesToString;
export const hasTimeframeOverlap = Helper.hasTimeframeOverlap;

export const arrayToObject = (array, key) => {
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const mergeLists = (l1, l2, key) => {
  // console.log('mL');
  // console.log(l1);
  // console.log(l2);
  // console.log(key);
  // var indexed = arrayToObject(l2, key);
  // console.log('indexed');
  // return _.map(l1, function(item){
  //     return indexed[item[key]] ? indexed[item[key]] : item;
  // });
};

export const decrypt = (ciphertext, ek, iv) => {
  const decryptedBytes = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Hex.parse(ek), {
    iv: CryptoJS.enc.Hex.parse(iv),
    mode: CryptoJS.mode.CTR,
    format: CryptoJS.format.Hex,
    padding: CryptoJS.pad.NoPadding,
  });

  const result = CryptoJS.enc.Utf8.stringify(decryptedBytes);

  return result;
};

export const getLogo = (siteBranding) => {
  if (siteBranding && siteBranding.Logo) {
    return siteBranding.Logo;
  }
  return logo;
};

export const capitalize = (string) => {
  if (_.isEmpty(string)) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeWhitespace = (input) => {
  if (!input) {
    return input;
  }
  return input.replace(/ /g, '');
};

export const get300 = (url) => {
  if (!url) {
    return url;
  }
  if (url.indexOf('https://plussprdstorage.blob.core.windows.net/') !== -1) {
    return url.replace('https://plussprdstorage.blob.core.windows.net/', 'https://plusscdn.azureedge.net/');
  }
  if (url.indexOf('https://plusscdn.azureedge.net/') !== -1) {
    return url.replace('/uploads/', '/uploads300/').replace('/general/', '/general300/');
  }
  if (url.indexOf(baseUploadsUrl) !== -1 || url.indexOf(baseLibraryUrl) !== -1) {
    const extension = getExtension(url);
    let urlToUse = url;
    if (extension !== 'jpg') {
      urlToUse = `${url.substring(0, url.length - (extension.length + 1))}.jpg`;
    }
    return urlToUse.replace('/1400/', '/uploads/').replace('/uploads/', '/300/');
  }
  return url;
};

export const getCompressed = (url) => {
  if (!url || typeof url !== 'string') return '';
  return url.replace('/uploads/', '/compressed/');
};

export const getDateFromTimeDatePickers = (dateInput, timeInput) => {
  const dateObject = new Date(dateInput);
  const time = getTimepickerTime(timeInput);
  dateObject.setHours(time.hours);
  dateObject.setMinutes(time.minutes);
  return dateObject;
};

export const getUTCFromTimeDatePickers = (dateInput, timeInput) => {
  const dateObject = getDateFromTimeDatePickers(dateInput, timeInput);
  return moment.utc(dateObject);
};

export const getAnA = (subject) => {
  if (_.isEmpty(subject)) {
    return 'a';
  }
  const lowered = subject.toLowerCase();
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.indexOf(lowered[0]) > -1 ? 'an' : 'a';
};

export const getPluralS = (count) => {
  if (count === 1) {
    return '';
  }
  return 's';
};

export const getPluralOptions = (count, singular, plural) => {
  if (count === 1) {
    return singular;
  }
  return plural;
};

export const getPercentage = (count, total) => {
  if (!count) {
    return '0%';
  }
  if (!total) {
    return '100%';
  }
  return `${Math.round((100 * count) / total)}%`;
};

export const getUserPreview = (user) => {
  return {
    displayName: user.displayName,
    id: user.Id,
    profilePic: user.profilePic || defaultProfileImage,
  };
};

export const usersToPreviews = (users) => {
  return _.map(users, getUserPreview);
};

export const formatCurrency = (number, locale = 'en-AU', curencyCode = DEFAULT_CURRENCY, shouldFix = false) => {
  const formatter = Intl.NumberFormat(curencyCode === 'NZD' ? 'en-NZ' : locale, {
    style: 'currency',
    currency: curencyCode,
    minimumFractionDigits: 2,
  });
  let formattedNumber = number;
  if (shouldFix && formatter.format(formattedNumber).indexOf('NaN') > -1) {
    formattedNumber = number.replace(/[^\d.-]/g, '');
  }
  return formatter.format(formattedNumber);
};

export const currencyToFloat = (number) => {
  let result = parseFloat(number);
  if (isNaN(result)) {
    result = parseFloat(number.replace(/[^\d.-]/g, ''));
    if (isNaN(result)) {
      result = 0;
    }
  }
  return result;
};

export const getCurrencySymbol = () => {
  return formatCurrency(0).replace(/[0-9.]+/g, '');
};

export const textToPrice = (text) => {
  return parseFloat(typeof text === 'string' ? text.replace(getCurrencySymbol(), '') : text, 10);
};

export const isCurrency = (text) => {
  if (_.isNil(text) || text === '') return false;
  const price = textToPrice(text);
  // console.log('isCurrency', _.isNumber(price), !Number.isNaN(price), price);
  return _.isNumber(price) && !Number.isNaN(price) ? price : false;
};

export const isKiosk = (type) => {
  return type === 'KIOSK' || type === 'FORMKIOSK' || type === 'SIGNINKIOSK';
};

export const isContentSource = (site) => {
  if (`${baseClient}-${baseStage}` !== contentSource) {
    return false;
  }
  return site === contentSourceSite;
};

export const formatDuration = (msDuration) => {
  const diff = moment.duration(msDuration);

  let result = '';
  const days = Math.floor(diff.asDays());
  if (days > 0) {
    result += `${days} day${getPluralS(days)} `;
    diff.add(-days, 'd');
  }
  const hours = Math.floor(diff.asHours());
  if (hours > 0) {
    result += `${hours} hour${getPluralS(hours)} `;
    diff.add(-hours, 'h');
  }
  const minutes = Math.floor(diff.asMinutes());
  if (minutes > 0) {
    result += `${minutes} minute${getPluralS(minutes)} `;
    diff.add(-minutes, 'm');
  }
  if (_.isEmpty(result)) {
    return 'Less than a minute';
  }
  return result.trim();
};

export const readStorageWithCookie = (key, isJson) => {
  const value = window.localStorage.getItem(key);
  if (value) {
    if (isJson) {
      try {
        return JSON.parse(value);
      } catch (e) {
        console.log('parse error', e);
      }
      return null;
    }
    return value;
  }
  if (isJson) {
    return Cookies.getJSON(key);
  }
  return Cookies.get(key);
};

export const clearLocalStorage = (key) => {
  window.localStorage.removeItem(key);
  Cookies.remove(key);
};

export const TIMESTAMPFORMAT = 'D MMM YYYY h:mma';

export const featureInfo = [
  ...extensionFeatureInfo,
  {
    key: 'dashboard',
    order: 1,
    text: 'Dashboard',
    icon: 'dashboard',
    isFontAwesome: false,
    url: '/mastermenu',
    countProps: null,
    visibleExps: null,
    isHubLink: true,
  },
  {
    key: 'featurePicker',
    order: 5,
    text: 'Feature Picker',
    icon: 'mobile',
    iconStyle: { fontSize: 24 },
    isFontAwesome: true,
    url: '/featurepicker',
    visibleExps: { type: 'permission', value: 'featurePicker' },
    countProps: null,
    isHubLink: true,
  },
  {
    key: 'branding',
    order: 6,
    text: 'Branding',
    icon: 'paint-brush',
    isFontAwesome: true,
    url: '/branding/edit',
    visibleExps: { type: 'permission', value: 'featurePicker' },
    isHubLink: true,
  },
  {
    key: 'analytics',
    order: 3,
    text: 'Analytics',
    icon: 'line-chart',
    isFontAwesome: true,
    url: '/analytics',
    countProps: null,
    isHubLink: true,
    visibleExps: null,
  },
  {
    key: 'events',
    order: 4,
    text: 'Events',
    icon: 'event',
    isFontAwesome: false,
    url: '/events',
    addUrl: '/events/event',
    addPermission: 'events',
    singularName: 'event',
    countProps: ['eventSubmissions', 'length'],
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'events' },
        {
          type: 'or',
          exps: [
            { type: 'permission', value: 'events' },
            { type: 'permission', value: 'eventAttendance' },
            { type: 'permission', value: 'eventSubmit' },
          ],
        },
      ],
    },
    featurePickerContent: EventsContent,
    featurePickerInfo: {
      image:
        'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a10a6c0e45c1bafcc84c6ca732/events.jpg',
      text: (
        <>
          <p>Calendars, Event Schedules, Reservation Lists.</p>
          <p>The Events your community puts on come to life with this feature.</p>
          <p>
            Collect payments, remind attendees, get those big events promoted early and they'll be coming back for more. Print event
            posters, and upload video to the Event so others can look back at it.
          </p>
          <p>It's all here.</p>
        </>
      ),
      layoutDisclaimer: true,
    },
  },
  {
    key: 'alerts',
    order: 3,
    text: 'Alerts',
    icon: 'exclamation-triangle',
    isFontAwesome: true,
    url: '/alerts',
    addUrl: '/alerts/addalert',
    addPermission: 'alerts',
    singularName: 'alert',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'alerts' },
        { type: 'permission', value: 'alerts' },
      ],
    },
  },
  {
    key: 'users',
    order: 2,
    text: 'Users',
    icon: 'people',
    isFontAwesome: false,
    url: '/usershub',
    addUrl: '/usershub/adduser',
    addPermission: 'userManagement',
    singularName: 'user',
    countProps: null,
    isHubLink: true,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'users' },
        { type: 'permission', value: 'userManagement' },
      ],
    },
  },
  {
    key: 'gallery',
    order: 6,
    text: 'Gallery',
    icon: 'picture-o',
    isFontAwesome: true,
    url: '/imageLibrary',
    addPermission: 'addToPublishingImageLibrary',
    singularName: 'gallery image',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'gallery' },
        { type: 'permission', value: 'addToPublishingImageLibrary' },
      ],
    },
    featurePickerContent: GalleryContent,
    featurePickerInfo: {
      image:
        'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/fb65f4c94f4498e7770240fbd9/gallery.jpg',
      text: (
        <>
          <p>All of the great imagery from your community events, sites and people.</p>
          <p>
            Create Gallery folders to let people see more, organise all of your photos, and let the community submit their own. Pictures
            still tell a thousand words, so Galleries are easy updates.
          </p>
        </>
      ),
      layoutDisclaimer: true,
    },
  },
  {
    key: 'services',
    order: 7,
    text: 'Services',
    icon: 'stethoscope',
    isFontAwesome: true,
    url: '/services',
    addUrl: '/services/service',
    addPermission: 'services',
    singularName: 'service',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'services' },
        { type: 'permission', value: 'services' },
      ],
    },
  },
  {
    key: 'sponsors',
    order: 8,
    text: 'Sponsors',
    icon: 'star-o',
    isFontAwesome: true,
    url: '/sponsors',
    addUrl: '/sponsors/sponsor',
    addPermission: 'services',
    singularName: 'sponsor',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'services' },
        { type: 'permission', value: 'services' },
      ],
    },
  },
  {
    key: 'bookings',
    order: 8,
    text: 'Bookings',
    icon: 'ticket',
    isFontAwesome: true,
    url: '/bookings',
    addUrl: '/bookings/booking',
    addPermission: 'bookings',
    singularName: 'booking',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        {
          type: 'or',
          exps: [
            { type: 'feature', value: 'services' },
            { type: 'feature', value: 'facilities' },
          ],
        },
        { type: 'permission', value: 'bookings' },
      ],
    },
  },
  {
    key: 'info',
    order: 11,
    text: 'Information',
    icon: 'info',
    isFontAwesome: false,
    url: '/info',
    addUrl: '/info/infopage',
    addPermission: 'infoPages',
    singularName: CONST_STRINGS.FAQ_ENTRY,
    countProps: null,
    visibleExps: {
      type: 'or',
      exps: [
        {
          type: 'and',
          exps: [
            { type: 'feature', value: 'importantContacts' },
            { type: 'permission', value: 'importantContacts' },
          ],
        },
        {
          type: 'and',
          exps: [
            { type: 'feature', value: 'maps' },
            { type: 'permission', value: 'maps' },
          ],
        },
        {
          type: 'and',
          exps: [
            { type: 'feature', value: 'infoPages' },
            { type: 'permission', value: 'infoPages' },
          ],
        },
        // {
        //   type: 'and',
        //   exps: [
        //     { type: 'feature', value: 'news' },
        //     { type: 'permission', value: 'welcomePages' },
        //   ],
        // },
        { type: 'permission', value: 'terms' },
      ],
    },
  },
  {
    key: 'surveys',
    order: 12,
    text: 'Surveys',
    icon: 'pie-chart',
    isFontAwesome: true,
    url: '/polls',
    addUrl: '/polls/poll',
    addPermission: 'polls',
    singularName: 'survey',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'surveys' },
        { type: 'permission', value: 'polls' },
      ],
    },
  },
  {
    key: 'facilities',
    order: 9,
    text: CONST_STRINGS.FACILITIES,
    icon: 'facility',
    isFontAwesome: false,
    url: '/facilities',
    addUrl: '/facilities/facility',
    addPermission: 'facilities',
    singularName: 'facility',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'facilities' },
        { type: 'permission', value: 'facilities' },
      ],
    },
  },
  {
    key: 'offers',
    order: 13,
    text: 'Offers',
    icon: 'shopping-bag',
    isFontAwesome: true,
    url: '/offers',
    addUrl: '/offers/offer',
    addPermission: 'offers',
    singularName: 'offer',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'offers' },
        { type: 'permission', value: 'offers' },
      ],
    },
    linkableContent: {
      hasContent: true,
      //TODO expose content
    },
  },
  {
    key: 'signin',
    order: 1,
    text: 'Sign Ins',
    icon: 'signin',
    isFontAwesome: false,
    url: '/signinHub',
    addUrl: null,
    addPermission: 'visitors',
    singularName: 'visitor',
    countProps: null,
    isToolLink: true,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'visitors' },
        { type: 'permission', value: 'visitors' },
      ],
    },
  },
  {
    key: 'food',
    order: 15,
    text: 'Restaurant Manager',
    icon: 'cutlery',
    isFontAwesome: true,
    url: '/food',
    addUrl: null,
    addPermission: 'food',
    singularName: 'dish',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'food' },
        { type: 'permission', value: 'food' },
      ],
    },
  },
  {
    key: 'payment',
    order: 2,
    text: 'Payment',
    icon: 'usd',
    isFontAwesome: true,
    url: '/payment',
    addUrl: null,
    addPermission: null,
    singularName: 'payment',
    countProps: null,
    isToolLink: true,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'props', value: 'paymentEnabled' },
        {
          type: 'or',
          exps: [
            { type: 'permission', value: 'managePayments' },
            { type: 'permission', value: 'viewPayments' },
          ],
        },
      ],
    },
  },
  {
    key: 'tvs',
    order: 3,
    text: 'TVs',
    icon: 'tv',
    isFontAwesome: true,
    url: '/activatetv',
    singularName: 'tv',
    countProps: null,
    isToolLink: true,
    visibleExps: { type: 'permission', value: 'activateDevices' },
  },
  {
    key: 'automation',
    order: 4,
    text: 'Automation',
    icon: 'bolt',
    isFontAwesome: true,
    url: '/automationHub',
    addUrl: null,
    addPermission: null,
    singularName: 'automation',
    countProps: null,
    isToolLink: true,
    visibleExps: { type: 'master', value: true },
  },
  {
    key: 'master',
    order: 5,
    text: 'Master',
    icon: 'tasks',
    isFontAwesome: true,
    url: '/master',
    addUrl: null,
    addPermission: null,
    singularName: 'master',
    countProps: null,
    isToolLink: true,
    visibleExps: { type: 'master', value: true },
  },
];

export const getLinkedContentTypes = (enabledFeatures) => {
  const validTypes = _.filter(enabledFeatures, (f) => {
    const fInfo = getFeatureInfo(f);
    return fInfo && fInfo.linkableContent && fInfo.linkableContent.hasContent;
  });
  return validTypes.map((t) => {
    const fInfo = getFeatureInfo(t);
    return {
      Key: t,
      Title: fInfo.text,
    };
  });
};

export const getFeatureInfo = (type) => {
  switch (type) {
    case 'maps':
      return {
        key: 'maps',
        icon: 'map',
        text: 'Maps',
        isFontAwesome: true,
        url: '/info',
        addUrl: '/info/map',
        addPermission: 'maps',
        singularName: 'map',
      };
    case 'importantContacts':
      return {
        key: 'importantContacts',
        icon: 'address-card-o',
        text: 'Important Contacts',
        isFontAwesome: true,
        url: '/info',
        addUrl: '/info/importantcontact',
        addPermission: 'importantContacts',
        singularName: 'contact',
      };
    case 'people':
      return {
        key: 'users',
        text: 'People',
        icon: 'people',
        isFontAwesome: false,
        featurePickerContent: PeopleContent,
        featurePickerInfo: {
          image:
            'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/e4a5948c40908e2137f0a65eb1/groups.jpg',
          text: (
            <>
              <p>Profile lists for your community - so everyone can can see and contact each other.</p>
              <p>
                These lists can be used for Direct Messaging, phone directories, sharing details with the community and to search through
                the other members you're in with. still tell a thousand words, so Galleries are easy updates.
              </p>
            </>
          ),
          layoutDisclaimer: true,
        },
      };
    case 'profile':
      return {
        key: 'profile',
        text: 'Profile',
      };
    case 'feedback':
      return {
        key: 'feedback',
        text: 'Give Feedback',
      };
    case 'info':
    case 'infoPages':
      return featureInfo.find((n) => n.key === 'info');
    default:
      let info = featureInfo.find(
        (n) =>
          n.key === type ||
          // check for matching alias
          _.some(extensionAliases, (alias) => {
            return (
              alias.key === n.key &&
              _.some(alias.aliases, (aliasKey) => {
                return aliasKey === type;
              })
            );
          }),
      );
      return info || { key: type, icon: type };
  }
};

export const evaluateVisibility = (exp, auth, state, props) => {
  if (!exp) return true;
  switch (exp.type) {
    case 'and':
      return exp.exps.every((x) => evaluateVisibility(x, auth, state, props));
    case 'or':
      return exp.exps.some((x) => evaluateVisibility(x, auth, state, props));
    case 'feature':
      return isFeatureEnabled(auth.features, exp.value);
    case 'permission':
      return validateAccess(auth.site, exp.value, auth);
    case 'state':
      return state[exp.value];
    case 'props':
      return props[exp.value];
    case 'master':
      return isTheBest(auth, exp.value);
    default:
      return false;
  }
};

export const getVisibleFeatures = (auth, state, props) => {
  const ordered = _.orderBy(featureInfo, 'order');
  return _.filter(ordered, (item) => {
    return evaluateVisibility(item.visibleExps, auth, state, props);
  });
};
