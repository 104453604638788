import config from './config.json';

export const versionNumber = config.version;

export const baseUrl = 'https://us-central1-aveo-dev.cloudfunctions.net';
//export const baseUrlAWS = "https://2mshak32he.execute-api.ap-southeast-2.amazonaws.com";
export const baseStage = 'prd';
export const baseClient = 'aveo';
export const clientName = 'Aveo';
export const requireLogin = true;
export const requiresUserTermsAndConditions = true;
export const baseStorageUrl = `https://${baseClient}-${baseStage}-media.s3.ap-southeast-2.amazonaws.com/`;
export const baseUploadsUrl = `https://${baseClient}-${baseStage}-uploads.s3.ap-southeast-2.amazonaws.com/`;
export const baseLibraryUrl = `https://pluss60-dev-uploads.s3.ap-southeast-2.amazonaws.com/`;
export const baseAPIUrl = `https://${baseClient}.pluss60-api.com`;
export const defaultProfileImage =
  'https://pluss60-demo-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:97769b56-25ec-41c0-a73c-6c2c4140f629/public/3d2433c6419383beb1078036b7/tinydefaultprofile.png';
export const logo = 'https://plusscdn.azureedge.net/uploads/23046-1/636730832705294464704aveotogether.png';

export const communityConfig = {
  banner:
    'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a07f89944830bd08ab481efbe6/plussbanner3.png',
};
export const commonAPIUrl = 'https://pluss60.pluss60-api.com';
export const commonAPIStage = 'demo';

export const eventsHaveTags = true;
export const newsHaveTags = true;
export const requiresEmail = false;
export const contentSource = 'pluss60-demo';
export const contentSourceSite = 'SuperHQ';

export const hasAvailableNews = false;
export const hasAvailableEvents = true;
export const DEFAULT_ALLOW_COMMENTS = true;
export const ENABLE_EVENT_PRINT = false;

export const PHONE_COUNTRY_CODE = '+61';
export const PHONE_COUNTRY = 'au';
export const PHONE_NUMBER_MINLENGTH = 12;

export const DEFAULT_CURRENCY = 'AUD';

export const testflightLink = '';
export const androidApk = '';

export const iOSLink = 'https://apps.apple.com/tt/app/pluss-communities/id1503581167';
export const androidLink = 'https://play.google.com/store/apps/details?id=com.pluss.prd';

export const AWS_AUTH_CONFIG = {
  userPoolId: 'ap-southeast-2_7EFxBCox4',
  userPoolWebClientId: '450qudvso5ed21iev1te6k5oaa',
  region: 'ap-southeast-2',
  identityPoolId: 'ap-southeast-2:b0dbde11-aa5a-43d5-a932-1f4c214847a5',
  mandatorySignIn: false,
};

export const AWS_STORAGE_CONFIG = {
  bucket: `${baseClient}-${baseStage}-media`,
  region: 'ap-southeast-2',
  identityPoolId: AWS_AUTH_CONFIG.identityPoolId,
  customPrefix: {
    public: '',
  },
};

export const AWS_UPLOADS_BUCKET = `${baseClient}-${baseStage}-uploads`;

export const GA_TRACKING_ID = 'UA-185105694-1';

export const MAKE_API_KEY = '1162b2f93e93eb238e3e29aa602c60369dc6c297';

export const canCopyDummy = true;

export const UTC_OFFSET = 10;

export const paymentConfig = {
  stripeScript: 'https://js.stripe.com/v3/', // stripe
};

export const automationConfig = {
  awsAccountEmail: 'aws-account@plusscommunities.com',
  devAccountEmail: 'developer@plusscommunities.com',
  helpDeskEmail: 'helpdesk@plusscommunities.com',
  websiteUrl: 'https://plusscommunities.com',
  privacyUrl: 'https://plusscommunities.com/privacy',
  // whiteLabelUrl: 'https://dev.pluss60-api.com',
  // whiteLabelStage: 'dev',
  whiteLabelUrl: 'https://pluss60.pluss60-api.com',
  whiteLabelStage: 'demo',
};
