import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SideNavItem } from './';
import { typeActions } from '../webapi';
import { setAuth } from '../actions';
import { isTheBest, validateAccess, isFeatureEnabled } from '../session';
import { Site } from './Site';
import { featureInfo, getVisibleFeatures } from '../helper';
import { extensionReducers } from '../config/features';

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      width: 0,
      height: 0,
      url: window.location.pathname,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getUserTypes();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isUndefined(nextProps.auth.userTypes)) {
      this.setState({ types: nextProps.auth.userTypes });
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getUserTypes() {
    if (!_.isUndefined(this.props.auth.site)) {
      typeActions
        .getUserTypes(this.props.auth.site)
        .then((res) => {
          this.props.setAuth({ userTypes: res.data });
        })
        .catch((error) => {});
    }
  }

  getItemText = (key, text) => {
    if (!this.props.strings || !this.props.strings.sideNav || !this.props.strings.sideNav[key]) {
      return text;
    }
    return this.props.strings.sideNav[key];
  };

  updateUrlPath() {
    setTimeout(() => {
      this.setState({ url: window.location.pathname });
    }, 100);
  }

  renderDefaultNav() {
    return (
      <div>
        <SideNavItem
          Text={'Dashboard'}
          Icon={'dashboard'}
          Link={'/mastermenu'}
          onUrlChange={this.updateUrlPath.bind(this)}
          url={this.state.url}
        />
        <SideNavItem
          Text={'Permissions'}
          Icon={'lock'}
          Link={'/permissions'}
          onUrlChange={this.updateUrlPath.bind(this)}
          url={this.state.url}
          isFontAwesome
        />
        {/* <SideNavItem
            Text={'Welcome Information'}
            Icon={'handshake-o'}
            Link={'/welcome'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            isFontAwesome
          /> */}
      </div>
    );
  }

  renderHQNav() {
    return (
      <div>
        <SideNavItem
          Text={'Dashboard'}
          Icon={'dashboard'}
          Link={'/mastermenu'}
          onUrlChange={this.updateUrlPath.bind(this)}
          url={this.state.url}
        />
        {validateAccess(this.props.auth.site, 'userManagement', this.props.auth) && (
          <SideNavItem Text={'Users'} Icon={'people'} Link={'/usershub'} onUrlChange={this.updateUrlPath.bind(this)} url={this.state.url} />
        )}
        {validateAccess(this.props.auth.site, 'newsletter', this.props.auth) && _.includes(this.props.auth.features, 'news') && (
          <SideNavItem Text={'News'} Icon={'news'} Link={'/news'} onUrlChange={this.updateUrlPath.bind(this)} url={this.state.url} />
        )}
        {validateAccess(this.props.auth.site, 'polls', this.props.auth) && _.includes(this.props.auth.features, 'surveys') && (
          <SideNavItem
            Text={'Surveys'}
            Icon={'pie-chart'}
            isFontAwesome
            Link={'/polls'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
          />
        )}
        {validateAccess(this.props.auth.site, 'services', this.props.auth) && _.includes(this.props.auth.features, 'services') && (
          <SideNavItem
            Text={'Services'}
            Icon={'stethoscope'}
            isFontAwesome
            Link={'/services'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
          />
        )}
        {isTheBest(this.props.auth, false) && (
          <SideNavItem
            Text={'Permissions'}
            Icon={'lock'}
            Link={'/permissions'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            isFontAwesome
          />
        )}
        {isTheBest(this.props.auth, true) && (
          <SideNavItem
            Text={'Automation'}
            Icon={'bolt'}
            Link={'/automationHub'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            isFontAwesome
          />
        )}
      </div>
    );
  }

  renderSideNavItems(section) {
    const { url } = this.state;
    const { auth } = this.props;
    const ordered = _.filter(_.orderBy(getVisibleFeatures(auth, this.state, this.props), 'order'), (f) => {
      if (section === 'hub') return f.isHubLink;
      if (section === 'tools') return f.isToolLink;
      return !f.isHubLink && !f.isToolLink;
    });
    return (
      <div>
        {ordered.map((item) => {
          const getCount = (keys, props = null, index = 0) => {
            if (!keys || keys.length === 0) return null;
            const currentProp = props ? props[keys[index]] : this.props[keys[index]];
            if (keys.length > ++index) return getCount(keys, currentProp, index);
            if (typeof currentProp !== 'number') throw new Error(`countProps does not evaluate to a number for ${item.text}`);
            return currentProp;
          };

          return (
            <SideNavItem
              key={item.key}
              Text={this.getItemText(item.key, item.text)}
              Icon={item.icon}
              iconStyle={item.iconStyle}
              isFontAwesome={item.isFontAwesome}
              isSVG={item.isSVGIcon}
              Link={item.url}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={url}
              count={getCount(item.countProps)}
            />
          );
        })}
      </div>
    );
  }

  renderSideNav() {
    if (this.props.auth.site === 'default') return this.renderDefaultNav();
    if (this.props.auth.site === 'hq') return this.renderHQNav();

    const settingsShown = _.some(['maintenanceTypes', 'feedback', 'terms', 'activateDevices'], (s) => {
      if (s === 'activateDevices') {
        return validateAccess(this.props.auth.site, s, this.props.auth);
      }
      return validateAccess(this.props.auth.site, s, this.props.auth) && isFeatureEnabled(this.props.auth.features, s);
    });

    return (
      <div className="sideNav_section">
        {this.renderSideNavItems('hub')}
        {settingsShown && (
          <SideNavItem
            Text={'Settings'}
            Icon={'settings'}
            Link={'/settings'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            className="sideNav-item-condensed"
          />
        )}
        <div className="sideNav_section_topDivider"></div>
        {this.renderSideNavItems('content')}
        <div className="sideNav_section_topDivider"></div>
        {this.renderSideNavItems('tools')}
      </div>
    );
  }

  render() {
    return (
      <div id="sideNavWrapper" className={`sideNav${this.props.condensedSidebar ? ' sideNav-condensed' : ''}`}>
        <div className="titleWrapper">
          <span className="fontLight">COMMUNITY</span>
          <span className="fontHeavy">MANAGER</span>
        </div>
        <Site />

        {this.renderSideNav()}
        <div className="sideNav_sliver"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, events, nav } = state;
  const props = {
    auth,
    eventSubmissions: events.submissions,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
    condensedSidebar: nav.condensedSidebar,
    strings: (state.strings && state.strings.config) || {},
  };
  if (extensionReducers) {
    const reducerKeys = Object.keys(extensionReducers);
    reducerKeys.forEach((key) => {
      props[key] = state[key];
    });
  }
  return props;
};

let exportObj = connect(mapStateToProps, { setAuth })(withRouter(SideNav));

export { exportObj as SideNav };
